<template>

  <div class="pb" v-if="edit_data">

     <div v-if="edit_data && edit_data.seed">
       <n-message-provider>
         <EditSeed
          :edit_data="edit_data.seed"
          />
        </n-message-provider>
     </div>

     <div v-else-if="edit_data && edit_data.nutrient">
       <n-message-provider>
         <EditNutrient
          :edit_data="edit_data.nutrient"
          />
        </n-message-provider>
     </div>

     <div v-else-if="edit_data && edit_data.lamp">
       <n-message-provider>
         <EditLamp
          :edit_data="edit_data.lamp"
          />
        </n-message-provider>
     </div>

     <div v-else-if="edit_data && edit_data.tent">
       <n-message-provider>
         <EditTent
          :edit_data="edit_data.tent"
          />
        </n-message-provider>
     </div>

     <div v-else-if="edit_data && edit_data.grow_box">
       <n-message-provider>
         <EditGrowBox
          :edit_data="edit_data.grow_box"
          />
        </n-message-provider>
     </div>

     <div v-else-if="edit_data && edit_data.ventilation_fan">
       <n-message-provider>
         <EditVentilationFan
          :edit_data="edit_data.ventilation_fan"
          />
        </n-message-provider>
     </div>

     <div v-else-if="edit_data && edit_data.air_filter">
       <n-message-provider>
         <EditAirFilter
          :edit_data="edit_data.air_filter"
          />
        </n-message-provider>
     </div>

     <div v-else-if="edit_data && edit_data.air_conditioner">
       <n-message-provider>
         <EditAirConditioner
          :edit_data="edit_data.air_conditioner"
          />
        </n-message-provider>
     </div>

     <div v-else-if="edit_data && edit_data.controller">
       <n-message-provider>
         <EditController
          :edit_data="edit_data.controller"
          />
        </n-message-provider>
     </div>

     <div v-else-if="edit_data && edit_data.drip_system">
       <n-message-provider>
         <EditDripSystem
          :edit_data="edit_data.drip_system"
          />
        </n-message-provider>
     </div>

     <div v-else-if="edit_data && edit_data.hydroponic_system">
       <n-message-provider>
         <EditHydroponicSystem
          :edit_data="edit_data.hydroponic_system"
          />
        </n-message-provider>
     </div>

     <div v-else-if="edit_data && edit_data.co2_generator">
       <n-message-provider>
         <EditCo2Generator
          :edit_data="edit_data.co2_generator"
          />
        </n-message-provider>
     </div>

   </div>

</template>

<script setup>

import { NMessageProvider } from 'naive-ui'

import EditSeed from '@/pages/brands-manager/components/EditSeed'
import EditNutrient from '@/pages/brands-manager/components/EditNutrient'
import EditLamp from '@/pages/brands-manager/components/EditLamp'
import EditTent from '@/pages/brands-manager/components/EditTent'
import EditGrowBox from '@/pages/brands-manager/components/EditGrowBox'
import EditVentilationFan from '@/pages/brands-manager/components/EditVentilationFan'
import EditAirFilter from '@/pages/brands-manager/components/EditAirFilter'
import EditAirConditioner from '@/pages/brands-manager/components/EditAirConditioner'
import EditController from '@/pages/brands-manager/components/EditController'
import EditDripSystem from '@/pages/brands-manager/components/EditDripSystem'
import EditHydroponicSystem from '@/pages/brands-manager/components/EditHydroponicSystem'
import EditCo2Generator from '@/pages/brands-manager/components/EditCo2Generator'


const {$api} = useNuxtApp()
const route = useRoute();

const loadData = async function() { 
  const response = await $api.getB2BProduct(route.params['id'],route.query['tmp_id']);
  return response; 
}

const { pending: is_loading,  data: edit_data } = await useLazyAsyncData('edit_data', async () => await loadData())

useHead(useNuxtApp().$head.getBrandManager());

onUnmounted(() => {
  edit_data.value = null;
})

</script>

<style scoped>

</style>

<style>
 
.label {
  display: block;
  margin: 0em 0em 0.28571429rem 0em;
  color: rgba(0, 0, 0, 0.87);
  font-size: 0.92857143em;
  font-weight: bold;
  text-transform: none;
}

</style>